import { createStore } from "vuex";
import home from "./modules/home";
export default createStore({
  state: {
    isMobile: false,
  },
  modules: { home },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
  },
});
