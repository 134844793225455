<template>
  <nav class="nav" :class="{ bg: showNavBg }">
    <router-link tag="div" class="company" to="/">
      <img class="logo" src="@/assets/img/logo.png" alt="" />
      <p class="company-name">买尔哈巴(上海)信息有限公司</p>
    </router-link>
    <div class="nav-main" :class="{ isHome: router.name == 'home' }">
      <router-link class="nav-list" active-class="active" to="/"
        >首页</router-link
      >
      <router-link class="nav-list" active-class="active" to="/about"
        >服务与案例</router-link
      >
      <router-link class="nav-list" active-class="active" to="/connect"
        >联系我们</router-link
      >
    </div>
  </nav>
</template>
<script setup>
import { useRoute } from "vue-router";
import { useStore, mapState, mapMutations } from "vuex";
import { computed, onMounted, ref } from "vue";
const store = useStore();
// let showNavBg = computed(
//   mapState(["showNavBg"]).showNavBg.bind({ $store: store })
// );
let showNavBg = ref(false);
let scrollFun = () => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  showNavBg.value =
    scrollTop > 100 && ["about", "connect"].includes(router.name);
};
let router = useRoute();
onMounted(() => {
  window.addEventListener("scroll", scrollFun);
});
// let slide = computed(mapState(["slide"]).slide.bind({ $store: store }));
</script>

<style lang="scss">
.nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3.2rem 8.35rem 3.2rem 6.4rem;
  transition: background-color 0.5s;
  &.bg {
    background-color: rgba(21, 23, 33, 0.1);
    backdrop-filter: blur(30px);
  }
}
.company {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.company-name {
  font-size: 2.4rem;
  margin-left: 3.3rem;
  line-height: 3.4rem;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
}
.logo {
  height: 5.6rem;
  cursor: pointer;
}
.nav-main {
  font-size: 1.8rem;
  line-height: 5.6rem;
  flex: 1;
  text-align: right;
  padding-right: 9.5rem;
  position: relative;

  &.isHome::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-50%);
  }
}
.nav-list {
  margin-left: 124px;
  color: #b2b9c5;
  &.active {
    color: #fff;
    font-weight: bold;
  }
}
</style>
