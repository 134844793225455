<template>
  <div class="page">
    <div
      class="img-parent"
      :style="{
        'justify-content': props.align,
      }"
    >
      <template v-if="img">
        <img class="img" :src="img" alt="" />
      </template>
    </div>
    <div class="line">
      <div class="line-left"></div>
      <div class="line-main"></div>
      <div class="line-right"></div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watchEffect, ref } from "vue";
const props = defineProps({
  num: [Number, String],
  align: String,
});
let img = ref();
watchEffect(() => {
  try {
    img = require(`../assets/img/0${props.num}.png`);
  } catch {
    img = false;
  }
});
</script>
<style lang="scss" scoped>
.line {
  display: flex;
  align-items: flex-end;
  & > .line-main {
    width: 107px;
    height: 1px;
    opacity: 0.2;
    background: #fff;
  }
  .line-left,
  .line-right {
    width: 19px;
    height: 2px;
    background: #fff;
  }
}
.img-parent {
  display: flex;
  margin-bottom: 3rem;
}
.img {
  height: 2.1rem;
}
</style>
