export default {
  namespaced: true, // 命名空间
  state: {
    slide: 0,
    maxSlide: 5,
  },
  mutations: {
    setSlide(state, num) {
      state.slide = Math.min(num, state.maxSlide - 1);
    },
  },
};
