import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入全局组件
import PublicSection from "./components/PublicSection";
import PublicNav from "./components/PublicNav";
import PublicSwitch from "./components/PublicSwitch";
import PublicFooter from "./components/PublicFooter";
import PublicNum from "./components/PublicNum";
import "@/assets/css/swiper-bundle.css";
import animate from "animate.css";


// 注册全局组件
let app = createApp(App);
app.component("PublicSection", PublicSection);
app.component("PublicNav", PublicNav);
app.component("PublicSwitch", PublicSwitch);
app.component("PublicFooter", PublicFooter);
app.component("PublicNum", PublicNum);

app.use(router).use(store).mount("#app");
