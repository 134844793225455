<template>
  <PublicNav></PublicNav>
  <router-view />
  <!-- <PublicFooter></PublicFooter> -->
</template>
<script setup>
import { onMounted } from "vue";
import wow from "@/assets/js/wow";
import { useStore } from "vuex";
const store = useStore();
onMounted(() => {
  var wow = new WOW({
    boxClass: "wow",
    offset: 100,
    mobile: true,
    live: true,
  }).init();
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    store.commit("setIsMobile", true);
  } else {
    store.commit("setIsMobile", false);
  }
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
