<template>
  <div class="switch">
    <ul class="switch-main">
      <li
        class="switch-li"
        @click="setSlideFun(key)"
        :class="{ hover: key === slide }"
        v-for="(i, key) in maxSlide"
        :key="key"
      ></li>
    </ul>
  </div>
</template>
<script setup>
import { useStore, mapState, mapMutations } from "vuex";
import { ref, computed } from "vue";
const store = useStore();
let slide = computed(mapState("home", ["slide"]).slide.bind({ $store: store }));
let maxSlide = computed(() => store.state.home.maxSlide);
let setSlideFun = mapMutations("home", ["setSlide"]).setSlide.bind({
  $store: store,
});
</script>
<style lang="scss">
.switch {
  width: 8.35rem;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 1000;
}
.switch-main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.switch-li {
  width: 25px;
  height: 25px;
  margin-top: 47px;
  border-radius: 50%;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 50%;
    opacity: 0.2;
    width: 7px;
    height: 7px;
  }
  &:hover {
    cursor: pointer;
  }
  &.hover {
    border: 1px solid #fff;
    &:after {
      opacity: 1;
    }
  }
}
</style>
